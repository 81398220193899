<template>
    <div class="all-page">
        <home-nav ref="homeNavRef" class="m-hidden-view"></home-nav>
        <gzh-code-view class="m-hidden-view"></gzh-code-view>

        <div class="m-title">
            <div style="display: flex;height: 70px;align-items: center;" >
                <van-icon @click="back"  style="margin-right: 15px;" name="arrow-left" size="25"/>
                <span class="text-title">
                    {{contentInfo.contentName}}
                </span>
            </div>
        </div>

        <div class="p-title">
            <div style="height: 70px;padding-left: 30px;display: flex;align-items: center;color: #666666">
                <span>当前位置：</span>
                <span style="margin-left: 10px;">首页</span>
                <span style="margin-left: 10px;">></span>
                <span style="margin-left: 10px;">查看攻略</span>
            </div>
        </div>

        <div class="main-page">
<!--            <div style="background-color: #ffffff;font-size: 18px;font-weight: bold;">
                {{contentInfo.contentName}}
            </div>-->
            <div class="content-time">
                发表于 {{contentInfo.contentTime && contentInfo.contentTime.split(' ')[0]}}
            </div>

            <div class="content-text" v-html="contentInfo.contentText"></div>
        </div>

        <home-footer  class="m-footer m-hidden-view"></home-footer>
    </div>
</template>

<script>

    import downloadImg from '@/assets/downloadback/button_download.jpg'
    import guideImg from '@/assets/downloadback/button_guide.jpg'
    import logo from '@/assets/logo.png'
    import wjtImg from '@/assets/wjt.png'
    import QRCode from 'qrcodejs2'
    import {request, webTokenRequest} from '@/api/request'
    import {Toast, Dialog} from 'vant'

    import homeNav from "@/components/home/HomeNav";
    import homeFooter from "@/components/home/HomeFooter";
    import gzhCodeView from "@/components/home/GzhCodeView";
    import HomeLogin from "@/components/home/HomeLogin.vue";
    import notice from "@/api/notice";

    export default {
        name: 'contentDetail',
        components: {
            HomeLogin,
            homeNav,
            homeFooter,
            gzhCodeView,
        },
        data() {
            return {
                id: '',
                contentInfo: {},
            }
        },
        mounted() {
            this.id = this.$route.query.id;
            this.getContentInfo();
        },
        methods: {
            back() {
                this.$router.back();
            },
            getContentInfo() {
                let options = {
                    url:"/web/getContentInfo?id=" + this.id,
                    method: 'get'
                };
                request(options).then((res) => {
                    if (res.data.success === true) {
                        this.contentInfo = res.data.data;
                    }
                    else {
                        Toast(res.data.error.message);
                    }
                });
            },
        }
    }
</script>

<style scoped>

    .all-page {
        overflow: hidden;
        width: 100%;
        height: 100vh;
        overflow-y: auto;
        background-color: #f8f7f5;
    }
    .text-title {
        word-break: break-all;
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1; /* 这里是超出几行省略 */
    }
    .main-page {
        width: 100%;
        min-height: 400px;
        max-width: 900px;
        margin: 0 auto;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-content: center;
        align-items: center;
        box-sizing: border-box;
    }

    .p-title {
        width: 100%;
        max-width: 900px;
        height: 70px;
        background-color: #ffffff;
        border-radius: 8px;
        margin: 10px auto 0;
        z-index: 999;
        position: relative;
    }

    .m-title {
        display: none;
        font-size: 20px;
        color: #333333;
        width: 100%;
        height: 110px;
        text-align: left;
        align-items: flex-end;
        padding: 0 10px;
        position: fixed;
        z-index: 999;
        background-color: #f8f7f5;
        box-sizing: border-box;
    }
    .content-time {
        text-align: left;
        font-size: 12px;
        width: 100%;
        margin-top: 15px;
        margin-bottom: 15px;
        margin-left: 25px;
        color: #888888;
    }

    .content-text {
        margin-bottom: 20px;
    }
    @media screen and (max-width: 900px) {
        .content-time {
            margin-top: 0;
        }
        .content-text {
            width: 95%;
            margin: 0 auto 10px;
        }
        .main-page {
            margin-top: 110px;
        }
        .p-title {
            display: none;
        }
        .m-hidden-view {
            display: none !important;
        }
        .m-title {
            display: flex;
        }
        .m-footer {
            margin-bottom: 50px;
        }

    }
</style>
